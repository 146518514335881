<template>
<div>
   <div class="fens-header">
  <van-nav-bar
  title="粉丝详情"
  left-arrow
  :fixed="true"
   @click-left="$router.back(-1)"
/>
</div>

    <!----->
  <div class="fens-content">
    <div class="fens-main" v-if="fansDetail!=null">
      <div>
      <van-cell>
      <van-col span="4"><img :src='fansDetail.headImgUrl!=null?fansDetail.headImgUrl:""'  style="width:50px;height:50px;border-radius: 50%;"></van-col>
      <van-col span="16">
       <div class="box1 flex-row">
                  <span :class="fansDetail.status=='0'?'fens-word4  fens-box2 flex-col':'fens-word4  fens-box2-1 flex-col'">{{fansDetail.status=='0'?'有效':'无效'}}</span>
                  <span class="txt2">{{fansDetail.nickName!=null?fansDetail.nickName:''}}</span>
                  <img v-show="fansDetail.phone!=null?true:false"
                    class="icon4"
                    referrerpolicy="no-referrer"
                    src="img/phone.png" 
                   @click="copyWaybillNo('#copy-content')"  :data-clipboard-target="'#phone'" :id="'copy-content'"
                  />
                </div>
                   <div class="box1 flex-row">
                   <span class="txt3" id="phone">{{fansDetail.phone!=null?fansDetail.phone:''}}</span> <span class="word5">
                     {{fansDetail.followTime!=null?fansDetail.followTime:''}}</span>
                </div> 
      <div></div>
      </van-col>
      <van-col span="4">
         <!-- <div class="fens-mod3 flex-col"><span class="fens-word6" @click="isShow = true">联系TA</span></div> -->
         <a :href=" 'tel:'+fansDetail.phone" class="fens-word6 fens-mod3 flex-col"  >联系TA</a>
      </van-col></van-cell>
      </div>
<!-----分割线------->
      <div class="fens-main2"></div>
 <!-----分割线------->
  <div>
     <van-cell> <van-col span="6"  offset="4"><span style="  font-size: 12px;">粉丝{{fansDetail.fansNum!=null?fansDetail.fansNum:0}}</span>
      </van-col>
      <van-col  span="4" offset="2" ><div style=".vertical{
width:0;
height:30px;
border-left:1px solid rgba(0, 0, 0, 0.1);
}"></div></van-col>
      <van-col span="6" offset="2"><span style="  font-size: 12px;">有效粉丝{{fansDetail.effectiveFansNum!=null?fansDetail.effectiveFansNum:0}}</span>
      </van-col></van-cell>
      </div>
    </div>

<!------收益---------->
 <div >
    <div class="fens-main">
       <div>
      <van-cell><span>收益</span></van-cell>
      </div>
<!-----分割线------->
      <div class="fens-main2"></div>
 <!-----分割线------->
      <div>
      <van-cell> 
      <van-col span="8"  ><span style="display:block;font-weight: 700;margin-left: 20%">{{fansDetail.todayEarn!=null?fansDetail.todayEarn:0}}</span><span style="  font-size: 12px;">今日收益(元)</span></van-col>
       <van-col  span="1" ><div style=".vertical{
width:0;
height:40px;
border-left:1px solid rgba(0, 0, 0, 0.1);
}"></div></van-col>
      <van-col span="7" ><span  style="display:block;font-weight: 700;margin-left: 20%">{{fansDetail.monthEarn!=null?fansDetail.monthEarn:0}}</span><span style="  font-size: 12px;">本月收益(元)</span></van-col>
       <van-col  span="1"><div style=".vertical{
width:0;
height:40px;
border-left:1px solid rgba(0, 0, 0, 0.1);
}"></div></van-col>
      <van-col span="7" ><span  style="display:block;font-weight: 700;margin-left: 20%">{{fansDetail.totalEarn!=null?fansDetail.totalEarn:0}}</span><span style="  font-size: 12px;">累计收益(元)</span></van-col>
      </van-cell>
      </div>
    </div>
 </div>
<!------出单---------->
 <div>
    <div class="fens-main" >
       <div>
      <van-cell><span>出单</span></van-cell>
      </div>
<!-----分割线------->
      <div class="fens-main2"></div>
 <!-----分割线------->
      <div>
      <van-cell><span></span>本月出单：{{fansDetail.monthOrder}} 单</van-cell>
      <van-cell> 
      <van-col span="8"  offset="2" ><span style="display:block;font-weight: 700;margin-left: 20%">{{fansDetail.monthSelfOrder}}</span><span style="  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;">自购/分享订单</span></van-col>
   <van-col  span="2" offset="2" ><div style=".vertical{
width:0;
height:50px;
border-left:1px solid rgba(0, 0, 0, 0.1);
}"></div></van-col>
      <van-col span="8"  offset="2"><span  style="display:block;font-weight: 700;margin-left: 20%;">{{fansDetail.monthFansOrder}}</span><span style="  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;">粉丝订单</span></van-col>
      </van-cell>

      </div>
      <!-----分割线------->
      <div class="fens-main2"></div>
       <div>
      <van-cell><span></span>上月出单：{{fansDetail.lastOrder}} 单</van-cell>
      <van-cell> 
      <van-col span="8"  offset="2" ><span style="display:block;font-weight: 700;margin-left: 20%">{{fansDetail.lastSelfOrder}}</span><span style="  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;">自购/分享订单</span></van-col>
    <van-col  span="2" offset="2" ><div style=".vertical{
width:0;
height:50px;
border-left:1px solid rgba(0, 0, 0, 0.1);
}"></div></van-col>
      <van-col span="8"  offset="2"><span  style="display:block;font-weight: 700;margin-left: 20%">{{fansDetail.lastFansOrder}}</span><span style="  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;">粉丝订单</span></van-col>
      </van-cell>

      </div>
    </div>
 </div>
<!------粉丝情况---------->
 <div >
    <div class="fens-main">
       <div>
      <van-cell><span>粉丝情况</span></van-cell>
      </div>
<!-----分割线------->
      <div class="fens-main2"></div>
 <!-----分割线------->
      <div>
      <van-cell> 
      <van-col span="8"   ><span style="display:block;font-weight: 700;margin-left: 20%">{{fansDetail.todayFans}}</span><span style="  font-size: 12px;">今日邀粉(人)</span></van-col>
       <van-col  span="1" ><div style=".vertical{
width:0;
height:40px;
border-left:1px solid rgba(0, 0, 0, 0.1);
}"></div></van-col>
      <van-col span="7"  ><span  style="display:block;font-weight: 700;margin-left: 20%">{{fansDetail.weekFans}}</span><span style="  font-size: 12px;">近7天邀粉(人)</span></van-col>
       <van-col  span="1" ><div style=".vertical{
width:0;
height:40px;
border-left:1px solid rgba(0, 0, 0, 0.1);
}"></div></van-col>
      <van-col span="7"  ><span  style="display:block;font-weight: 700;margin-left: 20%">{{fansDetail.monthFans}}</span><span style="  font-size: 12px;">近30天邀粉(人)</span></van-col>
      </van-cell>
      </div>
    </div>
 </div>

    
<!----联系他----------->
<van-dialog
  use-slot
  v-model="isShow"
  theme="round-button"
  :showConfirmButton="false"
  title="联系他"
  :overlay="true"
  :close-on-click-overlay="true"
 >
   <div>
       <div style="text-align: center;">手机号:&nbsp;<span id="phone1">{{fansDetail.phone}}</span><img src="img/phone.png"   v-show="fansDetail.phone!=null?true:false"
        @click="copyWaybillNo('#copy-content1')"  :data-clipboard-target="'#phone1'" :id="'copy-content1'"/></div>
     <!--  <div style="text-align: center;"><span>微信号:&nbsp;未绑定微信号</span></div>-->
   </div>
</van-dialog>


  </div>
</div>
</template>
<script>
import {getObjDetail} from '@/api/fans/fans'
import { Toast } from "vant";
import Clipboard from 'clipboard';
  export default { 
   data() {
    return {
      isShow:false,
    fansDetail:'',
    };
  },
  created() {},
  mounted() {   
    
        this.getObjDetail()
     }, 
    
  methods: {
    onChange()
    {
      
    },
    getObjDetail()
    {
      let params ={id:this.$route.query.id}
      getObjDetail(params).then((res) => {
        if(res.data.code==0)
        {
         this.fansDetail=res.data.data
        }

                }).catch(err=>{
                        console.log(err)   
                    }
                )
    },
     copyWaybillNo(id) {
      
        let clipboard = new Clipboard(id);
        this.$nextTick(() => {
          clipboard.on('success', function () {
            Toast("复制成功");
            clipboard.destroy();
            
          });
          clipboard.on('error', function () {
            console.log('您的浏览器不支持点击复制');
            clipboard.destroy();
          });
        });
      },
  },
  watch: {},
  computed: {},
}
</script>
<style scoped>
.fens-content
{
 margin-top:50px
}
 .fens-main{
  margin-left: 1%;
  margin-right: 1%;
  background-color:white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  width:98%;

  margin-top:10px;
  }
  .fens-main2 {
  width: 98%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  align-self: center;
}
.fens-mod3 {
  border-radius: 11px;
  border: 1px solid rgba(255, 82, 0, 1);
  background-color: rgba(255, 255, 255, 1);

  justify-content: center;
  align-items: center;
}

.fens-word6 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 82, 0, 1);
  font-size: 11px;
  font-family: PingFangSC-Regular;
  line-height: 16px;
  text-align: center;
}
.fens-box2 {
  height: 14px;
  border-radius: 7px;
  border: 1px solid rgba(255, 82, 0, 1);
  background-color: rgba(255, 82, 0, 1);
width:30px;
  justify-content: center;
  align-items: center;
}

.fens-word4 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 8px;
  font-family: PingFangSC-Regular;
  line-height: 11px;
  text-align: center;
}
.fens-box2 {
  height: 14px;
  border-radius: 7px;
  border: 1px solid rgba(255, 82, 0, 1);
  background-color: rgba(255, 82, 0, 1);
width:30px;
  justify-content: center;
  align-items: center;
}
.fens-box2-1 {
  height: 14px;
  border-radius: 7px;
  border: 1px solid rgb(180, 178, 177);
  background-color: rgb(187, 183, 181);
width:30px;
  justify-content: center;
  align-items: center;
}
.txt3
{
 /* display: block;*/
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;
  text-align: left;
}
.word5
{
 /* display: block;*/
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 17px;
  text-align: left;
}
</style>




