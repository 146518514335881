/**
 * Copyright (C) 2021-2021
 * All rights reserved, Designed By www.tsinghuacloud.cn
 * 注意：
 * 本软件为www.tsinghuacloud.cn开发研制，未经购买不得使用
 * 购买后可获得全部源代码（禁止转卖、分享、上传到码云、github等开源平台）
 * 一经发现盗用、分享等行为，将追究法律责任，后果自负
 */

 import https from '@/router/https'

 //粉丝列表 
 export function getObj(param) {
     return https.fetchGet('/mallapp/fans/page', param); 
 }
  //粉丝详情
  export function getObjDetail(param) {
    return https.fetchGet('/mallapp/fans/fansDetail', param); 
}